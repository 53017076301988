import { Link } from "gatsby"
import React from "react"
import Page from "../../components/page"
import ResponsiveImageGrid from "../../components/responsive-image-grid"
import { themes } from "../../styles/themes"
import paths from "../../utils/paths"
import useScreenSize from "../../utils/useScreenSize"

const gameOfLightsGrid = (labels, isSmallScreen, pagePaths) => [
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/interiors/game-of-lights-vertical.jpg",
        alt: labels.interiorsGameOfLightsAlt,
        imgTitle: labels.interiorsGameOfLightsImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 49.25,
        src: "/images/interiors/xxx-commode-david-bovie-quilling.jpg",
        containerClass:
          "img-container interiors-xxx-commode-david-bovie-quilling-img-container",
        alt: labels.interiorsXxxCommodeDavidBowieQuillingAlt,
        imgTitle: labels.interiorsXxxCommodeDavidBowieQuillingImgTitle
      },
      {
        type: "image",
        weight: 49.25,
        src: "/images/interiors/wake-up-for-make-up-collection-purple.jpg",
        containerClass:
          "img-container interiors-wake-up-for-make-up-collection-purple-img-container",
        position: isSmallScreen ? undefined : "center 70%",
        alt: labels.interiorsWakeUpForMakeUpPurpleAlt,
        imgTitle: labels.interiorsWakeUpForMakeUpPurpleImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "html",
        weight: 100,
        html: (
          <div className="interiors-public-and-business-other-text-container">
            <p>{labels.interiorsOtherText}:</p>
            <Link
              className="interiors-public-and-business-other-link-text"
              to={pagePaths.publicAndBusinessInteriors}
            >
              <p>{labels.interiorsBusinessAndPublicOtherText}</p>
            </Link>
            <Link
              className="interiors-public-and-business-other-link-text"
              to={pagePaths.privateInteriors}
            >
              <p>{labels.interiorsPrivateOtherText}</p>
            </Link>
            <Link
              className="interiors-public-and-business-other-link-text"
              to={pagePaths.backRoomInteriors}
            >
              <p>{labels.interiorsBackRoomOtherText}</p>
            </Link>
          </div>
        )
      }
    ]
  }
]

export default function GameOfLights({ labels, ...props }) {
  const isSmallScreen = useScreenSize()

  return (
    <Page
      {...props}
      labels={labels}
      theme={themes(labels).ipsBlack(false)}
      title={labels.gameOfLightsInteriors}
      description={labels.gameOfLightsInteriorsDescription}
      keywords={labels.gameOfLightsInteriorsKeywords}
      ogUrl={paths(labels).gameOfLightsInteriors}
      ogImage="/images/og-images/game-of-lights-interiors.jpg"
      headerPadding={isSmallScreen}
    >
      <ResponsiveImageGrid
        grid={gameOfLightsGrid(labels, isSmallScreen, paths(labels))}
        margin={false}
      />
    </Page>
  )
}
